import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { IBetRequest } from "@finbackoffice/clientbff-client";
import { TicketStatus, TicketType, TranslationScopes } from "@finbackoffice/enums";
import classnames from "classnames";
import {
    AuthContext,
    ClientBFFContext,
    LocaleContext,
    UserAccountContext,
    BetSlipContext,
    ConfigContext,
    useRuntimeConfig,
    ErrorKeys,
    IBetSlipValidationError,
    OUTCOME_CHANGED_ERROR_CODES,
    useBetValidation,
} from "@finbackoffice/site-core";
import { IMtsError, ILimitError, IBetItemData, IBetSlipError } from "@finbackoffice/fe-core";
import Img from "components/base/img/Img";
import Button from "components/base/button/Button";
import ClientOnlyComponent from "components/base/client-only-component/client-only-component";
import { MarketUpdatesContext } from "contexts";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import Translate from "components/base/translate/Translate";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { SIRWidgetCore } from "components/base/widget/sir/SIRWidgetCore";
import { SIRWidgetLive } from "components/base/widget/sir/SIRWidgetLive";
import {
    useSelectedLiveGameId,
    useSelectedLiveSportId,
    useSelectedLiveRegionId,
    useLimits,
} from "hooks";
import { NotificationContext } from "contexts";
import SingleTotalStake from "./total-stake/single/SingleTotalStake";
import MultipleTotalStake from "./total-stake/multiple/MultipleTotalStake";
import BetSlipValidationError from "./validation-error/BetSlipValidationError";
import BetSlipItem from "./item/BetSlipItem";
import AcceptBetPlacement from "./accept-bet-placement/AcceptBetPlacement";
import styles from "./betslip-bets.module.sass";
import BetSlipError from "./error/BetSlipError";

const SystemTotalStake = dynamic(() => import("./total-stake/system/SystemTotalStake"), {
    ssr: false,
});

const BetSlipBets: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const client = useContext(ClientBFFContext);
    const { notice } = useContext(NotificationContext);
    const router = useRouter();
    const { currentWallet, deviceId } = useContext(UserAccountContext);
    const { isUserLoggedIn: isLoggedIn } = useContext(AuthContext);
    const { locale } = useContext(LocaleContext);
    const { siteMinBet, siteMaxWin } = useLimits();
    const { betSlipMaxSelections, siteMaxOdds, isBetProcessorEnabled } = useContext(ConfigContext);
    // const [showStatScoreWidget, setShowStatScoreWidget] = useState(false);
    const { unsubscribeAllMarkets } = useContext(MarketUpdatesContext);
    const {
        setBetType,
        removeBetItem,
        updateBetItem,
        removeAllBetItems,
        updateButtons,
        changeBetProcessing,
        setBetError,
        betType,
        systemBetStake,
        placeBetButtonEnabled,
        systemBetSelection,
        systemBetVariantsCount,
        multipleBetStake,
        multipleBetTotalOdds,
        betItems,
        betProcessing,
        showAcceptButton,
        betSlipError,
        betMode,
    } = useContext(BetSlipContext);
    const [validationError, setValidationError] = useState<IBetSlipValidationError | null>(null);
    const [showBetsAccepted, setShowBetsAccepted] = useState<boolean>(false);
    const betsAcceptedTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const gameId = useSelectedLiveGameId();
    const sportId = useSelectedLiveSportId();
    const regionId = useSelectedLiveRegionId();
    const {
        isMtsError,
        isErrorNeedToRemove,
        performBetValidate,
        needToRemove,
        isErrorOutcomeChanged,
        isOutcomeChanged,
    } = useBetValidation();

    const onPlaceBet = useCallback(async () => {
        if (!betProcessing && currentWallet && currentWallet.id) {
            changeBetProcessing(true);
            setBetError(null);
            switch (betType) {
                case TicketType.Single:
                    await Promise.all(
                        betItems.map(async (bet) => {
                            if (!bet.accepted && currentWallet.id) {
                                updateBetItem({
                                    outcomeId: bet.outcomeId,
                                    loading: true,
                                });

                                try {
                                    const response = await client.createTicket({
                                        language: locale,
                                        device_id: deviceId || "",
                                        wallet_id: currentWallet.id,
                                        type: TicketType.Single,
                                        mode: betMode,
                                        amount: parseFloat(bet.stake).toString(),
                                        bets: [
                                            {
                                                event_id: bet.gameId,
                                                market_id: bet.marketId,
                                                outcome_id: bet.outcomeId,
                                                odds: bet.outcomeValue,
                                            },
                                        ],
                                    });

                                    if (response.status === TicketStatus.Accepted) {
                                        updateBetItem({
                                            outcomeId: bet.outcomeId,
                                            loading: false,
                                            accepted: true,
                                        });
                                    }
                                } catch (err: any) {
                                    const error = err.response?.data;
                                    const statusCode: number = err.response?.status;
                                    if (error) {
                                        const mtsErrorCode =
                                            (error as IMtsError).details.code &&
                                            (error as IMtsError).details.code < 0
                                                ? -1 * error.details.code
                                                : error.details.code;
                                        const code = mtsErrorCode || statusCode;

                                        let updatedBetItem: Partial<IBetItemData> = {
                                            outcomeId: bet.outcomeId,
                                            loading: false,
                                            error: {
                                                stake: bet.stake,
                                                code,
                                                message: error.message,
                                                error: error.error,
                                                details: error.details,
                                                visible:
                                                    !OUTCOME_CHANGED_ERROR_CODES.includes(code) &&
                                                    !["market_error", "ticket_error"].includes(
                                                        error.error,
                                                    ),
                                            },
                                        };

                                        const recalculateLimits = (
                                            newBetMax: number,
                                            bet: Partial<IBetItemData>,
                                        ) => {
                                            let isPositive = false;

                                            if (newBetMax) {
                                                isPositive =
                                                    newBetMax >=
                                                    parseFloat(
                                                        (error as ILimitError).details.min_bet,
                                                    );

                                                if (bet.error) {
                                                    bet.error.isPositive = isPositive;
                                                }
                                            }

                                            if (isPositive) {
                                                bet.tempBetLimits = {
                                                    amount: newBetMax.toString(),
                                                    currency: error.details.currency,
                                                    min_bet: (error as ILimitError).details.min_bet,
                                                };
                                            }

                                            return bet;
                                        };

                                        const newBetMax = (error as ILimitError).details
                                            ?.recommended;
                                        if (newBetMax) {
                                            updatedBetItem = recalculateLimits(
                                                parseFloat(newBetMax),
                                                updatedBetItem,
                                            );
                                        }

                                        updateBetItem(updatedBetItem);
                                    } else {
                                        notice({
                                            type: "error",
                                            title: `Error: Status ${statusCode}`,
                                            message: "Server error",
                                        });
                                    }
                                }
                            }
                        }),
                    );
                    break;
                case TicketType.Express:
                    const expressBets: IBetRequest[] = [];
                    betItems.forEach((bet) => {
                        if (!bet.accepted) {
                            updateBetItem({
                                outcomeId: bet.outcomeId,
                                loading: true,
                            });
                            expressBets.push({
                                event_id: bet.gameId,
                                market_id: bet.marketId,
                                outcome_id: bet.outcomeId,
                                odds: bet.outcomeValue,
                            });
                        }
                    });
                    if (Boolean(expressBets.length)) {
                        try {
                            const response = await client.createTicket({
                                language: locale,
                                device_id: deviceId || "",
                                wallet_id: currentWallet.id,
                                type: TicketType.Express,
                                mode: betMode,
                                amount: parseFloat(multipleBetStake).toString(),
                                bets: expressBets,
                            });
                            if (response.status === TicketStatus.Accepted) {
                                setShowBetsAccepted(true);

                                betItems.forEach((bet) => {
                                    updateBetItem({
                                        outcomeId: bet.outcomeId,
                                        loading: false,
                                        accepted: true,
                                    });
                                });
                            }
                        } catch (err: any) {
                            const error = err.response?.data;
                            const statusCode: number = err.response?.status;

                            if (error) {
                                const code =
                                    (error as IMtsError).details.code < 0
                                        ? -1 * error.details.code
                                        : error.details.code || statusCode;

                                const updatedBetError: IBetSlipError = {
                                    stake: multipleBetStake,
                                    code,
                                    message: error.message,
                                    error: error.error,
                                    details: error.details,
                                    visible:
                                        !OUTCOME_CHANGED_ERROR_CODES.includes(code) &&
                                        !["market_error", "ticket_error"].includes(error.error),
                                };

                                const newBetMax = (error as ILimitError).details?.recommended;
                                let isPositive: boolean;

                                if (newBetMax) {
                                    isPositive =
                                        parseFloat(newBetMax) >=
                                        parseFloat((error as ILimitError).details.min_bet);

                                    updatedBetError.isPositive = isPositive;
                                }

                                setBetError(updatedBetError);

                                betItems.forEach((bet) => {
                                    updateBetItem({
                                        outcomeId: bet.outcomeId,
                                        loading: false,
                                    });
                                });
                            } else {
                                notice({
                                    type: "error",
                                    title: `Error: Status ${statusCode}`,
                                    message: "Server error",
                                });
                            }
                        }
                    }
                    break;
                case TicketType.System:
                    const systemBets: IBetRequest[] = [];
                    betItems.forEach((bet) => {
                        if (!bet.accepted) {
                            updateBetItem({
                                outcomeId: bet.outcomeId,
                                loading: true,
                            });
                            expressBets.push({
                                event_id: bet.gameId,
                                market_id: bet.marketId,
                                outcome_id: bet.outcomeId,
                                odds: bet.outcomeValue,
                            });
                        }
                    });
                    if (Boolean(systemBets.length)) {
                        try {
                            const response = await client.createTicket({
                                language: locale,
                                device_id: deviceId || "",
                                wallet_id: currentWallet.id,
                                type: TicketType.System,
                                mode: betMode,
                                amount: (
                                    parseFloat(systemBetStake) * systemBetVariantsCount
                                ).toString(),
                                system_count: systemBetSelection,
                                bets: systemBets,
                            });
                            if (response.status === TicketStatus.Accepted) {
                                setShowBetsAccepted(true);

                                betItems.forEach((bet) => {
                                    updateBetItem({
                                        outcomeId: bet.outcomeId,
                                        loading: false,
                                        accepted: true,
                                    });
                                });
                            }
                        } catch (err: any) {
                            const error = err.response?.data;
                            const statusCode: number = err.response?.status;

                            if (error) {
                                const code =
                                    (error as IMtsError).details.code < 0
                                        ? -1 * error.details.code
                                        : error.details.code || statusCode;

                                const updatedBetError: IBetSlipError = {
                                    stake: systemBetStake,
                                    code,
                                    message: error.message,
                                    error: error.error,
                                    details: error.details,
                                    visible:
                                        !OUTCOME_CHANGED_ERROR_CODES.includes(code) &&
                                        !["market_error", "ticket_error"].includes(error.error),
                                };

                                const newBetMax = (error as ILimitError).details?.recommended;
                                let isPositive: boolean;

                                if (newBetMax) {
                                    isPositive =
                                        parseFloat(newBetMax) >=
                                        parseFloat((error as ILimitError).details.min_bet);

                                    updatedBetError.isPositive = isPositive;
                                }

                                setBetError(updatedBetError);

                                betItems.forEach((bet) => {
                                    updateBetItem({
                                        outcomeId: bet.outcomeId,
                                        loading: false,
                                    });
                                });
                            } else {
                                notice({
                                    type: "error",
                                    title: `Error: Status ${statusCode}`,
                                    message: "Server error",
                                });
                            }
                        }
                    }
                    break;
                default:
                    console.log(`Unknown bet type ${betType}`);
                    return;
            }

            changeBetProcessing(false);
        }
    }, [
        betProcessing,
        currentWallet,
        betType,
        betItems,
        updateBetItem,
        client,
        locale,
        deviceId,
        betMode,
        notice,
        multipleBetStake,
        systemBetStake,
        systemBetVariantsCount,
        systemBetSelection,
        changeBetProcessing,
        setBetError,
    ]);

    const handleAcceptClick = () => {
        for (let i: number = betItems.length - 1; i >= 0; --i) {
            const betItem = betItems[i];
            if (
                (betItem.marketStatus &&
                    needToRemove(betItem.marketStatus, betItem.outcomeStatus)) ||
                (isMtsError(betItem.error?.error) && isErrorNeedToRemove(betItem.error?.code))
            ) {
                removeBetItem(betItem.outcomeId);
                setBetError(null);
            } else {
                let updatedBetItem = {};
                if (isOutcomeChanged(betItem)) {
                    updatedBetItem = { ...updatedBetItem, outcomeInitValue: betItem.outcomeValue };
                }

                if (isErrorOutcomeChanged(betItem.error?.code)) {
                    updatedBetItem = { ...updatedBetItem, error: undefined };
                }

                updateBetItem({
                    outcomeId: betItem.outcomeId,
                    ...updatedBetItem,
                });
            }
        }
    };

    useEffect(() => {
        const validate = () => {
            const { error, placeBetButtonEnabled, showAcceptButton, betItemErrors } =
                performBetValidate(COMMON_SITE_CONFIGS, betItems, {
                    isBetProcessorEnabled,
                    maxSelections: betSlipMaxSelections,
                    betType,
                    currentWallet,
                    multipleBetStake,
                    systemBetStake,
                    systemBetVariants: systemBetVariantsCount,
                    totalOdds: multipleBetTotalOdds,
                    maxOdds: siteMaxOdds,
                    minBet: siteMinBet,
                    siteMaxWin,
                    isLoggedIn,
                    betError: betSlipError,
                });

            updateButtons({
                placeBetButtonEnabled,
                showAcceptButton,
            });
            setValidationError(error);

            if (betItemErrors.length) {
                betItemErrors.forEach((error) => {
                    updateBetItem(error);
                });
            }
        };

        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentWallet,
        betItems,
        betType,
        siteMaxOdds,
        betSlipMaxSelections,
        siteMinBet,
        multipleBetStake,
        siteMaxWin,
        systemBetStake,
        systemBetVariantsCount,
        isLoggedIn,
        ,
        betSlipError,
    ]);

    useEffect(() => {
        if (betItems.length) {
            setShowBetsAccepted(false);
            if (betsAcceptedTimeoutRef.current) {
                clearTimeout(betsAcceptedTimeoutRef.current);
            }
        }
    }, [betItems.length, currentWallet]);

    useEffect(() => {
        if (betsAcceptedTimeoutRef.current) {
            clearTimeout(betsAcceptedTimeoutRef.current);
        }

        if (showBetsAccepted) {
            betsAcceptedTimeoutRef.current = setTimeout(() => {
                setShowBetsAccepted(false);
                removeAllBetItems();
                unsubscribeAllMarkets();
            }, 5000);
        }

        return () => {
            if (betsAcceptedTimeoutRef.current) {
                clearTimeout(betsAcceptedTimeoutRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBetsAccepted]);

    const onKeydownHandler = useCallback(
        (e: KeyboardEvent) => {
            if (e.keyCode === 13) {
                onPlaceBet();
            }
        },
        [onPlaceBet],
    );

    useEffect(() => {
        if (placeBetButtonEnabled) {
            document.addEventListener("keydown", onKeydownHandler);
        } else {
            document.removeEventListener("keydown", onKeydownHandler);
        }

        return () => {
            document.removeEventListener("keydown", onKeydownHandler);
        };
    }, [placeBetButtonEnabled, onKeydownHandler]);

    const multipleStakeDisabled = useMemo(() => {
        return (
            !!validationError?.errorKey &&
            [
                ErrorKeys.SELECT_BET,
                ErrorKeys.MULTIPLE_BET_MIN,
                ErrorKeys.MULTIPLE_BET_PICK_REACHED_MIN_REQUIREMENT,
            ].indexOf(validationError.errorKey) !== -1
        );
    }, [validationError?.errorKey]);

    const onBetTypeChange = useCallback(
        (val: TicketType) => {
            setBetType(val);
        },
        [setBetType],
    );

    const onRemoveAllClick = useCallback(() => {
        removeAllBetItems();
        unsubscribeAllMarkets();
    }, [removeAllBetItems, unsubscribeAllMarkets]);

    return (
        <>
            <nav className="main-tabs">
                <div
                    className={classnames(
                        "main-tabs-tab",
                        betType === TicketType.Single && "active",
                    )}
                    onClick={() => onBetTypeChange(TicketType.Single)}>
                    <Translate tid={`betType_${TicketType.Single.toLowerCase()}`} />
                </div>
                <div
                    className={classnames(
                        "main-tabs-tab",
                        betType === TicketType.Express && "active",
                    )}
                    onClick={() => onBetTypeChange(TicketType.Express)}>
                    <Translate tid={`betType_${TicketType.Express.toLowerCase()}`} />
                </div>
                {COMMON_SITE_CONFIGS.betslip.system.enable && (
                    <div
                        className={classnames(
                            "main-tabs-tab",
                            betType === TicketType.System && "active",
                        )}
                        onClick={() => onBetTypeChange(TicketType.System)}>
                        <Translate tid={`betType_${TicketType.System.toLowerCase()}`} />
                    </div>
                )}
            </nav>
            <ScrollComponent
                containerClass={styles.rightSide}
                contentClass={styles.rightSideContent}
                showScrollBar>
                <div className={styles.betSlipContent} data-testid="betslip-content">
                    <div className={styles.betSlipContentHeader}>
                        <span>
                            <Translate
                                tid="betSlip_selection"
                                namespace={TranslationScopes.BetSlip}
                            />
                        </span>
                        <span className={styles.pullRight}>
                            <Translate tid="betSlip_odds" namespace={TranslationScopes.BetSlip} />
                        </span>
                    </div>
                    {betItems.length ? (
                        <div className={styles.betItemsContainer}>
                            {betItems.map((betItem: IBetItemData) => (
                                <ErrorBoundary name={BetSlipItem.name} key={betItem.outcomeId}>
                                    <BetSlipItem bet={betItem} />
                                </ErrorBoundary>
                            ))}
                            {showBetsAccepted && (
                                <FadeInAnimation>
                                    <span
                                        className={styles.acceptedBet}
                                        data-testid="bets-accepted-container">
                                        <Img
                                            source={`${ASSETS_URL}/common/desktop/base-icons/account-verified.svg`}
                                            alt="Accepted"
                                            width={12}
                                            height={10}
                                        />
                                        <Translate
                                            tid="betSlip_yourBetsAccepted"
                                            namespace={TranslationScopes.BetSlip}
                                        />
                                    </span>
                                </FadeInAnimation>
                            )}
                        </div>
                    ) : (
                        !showAcceptButton && (
                            <div className={styles.noBetItem}>
                                <Translate
                                    tid="betSlip_noItems"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </div>
                        )
                    )}
                </div>
                {betType === TicketType.Single && <SingleTotalStake />}
                {betType === TicketType.Express && (
                    <MultipleTotalStake
                        validationError={validationError}
                        disabled={multipleStakeDisabled || betProcessing}
                        betError={betSlipError}
                        betItems={betItems}
                        totalOdds={multipleBetTotalOdds}
                    />
                )}
                {COMMON_SITE_CONFIGS.betslip.system.enable && betType === TicketType.System && (
                    <SystemTotalStake />
                )}
                {!betProcessing && validationError?.errorType && (
                    <BetSlipValidationError
                        errorType={validationError.errorType}
                        errorKey={validationError.errorKey}
                        replace={validationError.replace}
                    />
                )}
                <div className={styles.placeBetContainer}>
                    <span className={styles.removeAllButton} onClick={onRemoveAllClick}>
                        <Translate tid="betSlip_removeAll" namespace={TranslationScopes.BetSlip} />
                    </span>
                    {!betProcessing && showAcceptButton && (
                        <Button type="button" onClick={handleAcceptClick} variant="betSlipButton">
                            <Translate tid="betSlip_accept" namespace={TranslationScopes.BetSlip} />
                        </Button>
                    )}
                    <Button
                        type="button"
                        disabled={!placeBetButtonEnabled || betProcessing}
                        onClick={onPlaceBet}
                        data-testid="place-bet-button"
                        variant="betSlipButton">
                        <Translate tid="betSlip_placeBet" namespace={TranslationScopes.BetSlip} />
                    </Button>
                </div>
                {COMMON_SITE_CONFIGS.betslip.enableOddsChangeModeSelector && <AcceptBetPlacement />}
                {Boolean(betSlipError) && Boolean(betSlipError?.visible) && (
                    <BetSlipError error={betSlipError} />
                )}
                {/* {gameId && sportId && regionId && showStatScoreWidget && (
                    <StatScoreWidgetProvider>
                        <StatScoreWidget matchId={gameId} sportId={sportId} regionId={regionId} />
                    </StatScoreWidgetProvider>
                )} */}
                {COMMON_SITE_CONFIGS.liveMatchTracker &&
                    router.pathname.includes("/live") &&
                    gameId &&
                    regionId &&
                    sportId && (
                        <ErrorBoundary name={SIRWidgetLive.name}>
                            <ClientOnlyComponent>
                                <SIRWidgetLive
                                    key={router.query?.liveGameRoutes?.[2]}
                                    matchId={gameId.toString()}
                                    regionId={regionId}
                                    containerSelector="#bet-slip-container"
                                    sportId={sportId}>
                                    {(isLicenced, loading) => (
                                        <SIRWidgetCore isLicenced={isLicenced} loading={loading} />
                                    )}
                                </SIRWidgetLive>
                            </ClientOnlyComponent>
                        </ErrorBoundary>
                    )}
            </ScrollComponent>
        </>
    );
};

export default BetSlipBets;
