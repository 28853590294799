import { FC, useContext, useEffect, useMemo, useState } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { AuthContext, BetSlipContext } from "@finbackoffice/site-core";
import { useLoadI18nextResource } from "hooks";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import { Svg } from "components/base/svg/Svg";
import { SIRWidgetCore } from "components/base/widget/sir/SIRWidgetCore";
import { SIRWidgetModal } from "components/base/widget/sir/SIRWidgetModal";
import { DraggableContext } from "contexts";
import Draggable2 from "components/base/draggable/Draggable2";
import Loading from "components/base/loading/Loading";
import styles from "./betslip.module.sass";
import BetSlipHeader from "./header/BetSlipHeader";
import BetSlipHistoryBlock from "./history/BetSlipHistoryBlock";
import BetSlipBets from "./bets/BetSlipBets";

export type IBetSlipViewType = "bet_slip" | "history";

const BetSlip: FC = () => {
    const { items, clearAllItems } = useContext(DraggableContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const [view, setView] = useState<IBetSlipViewType>("bet_slip");
    const { betItems } = useContext(BetSlipContext);
    const { isReady: isTranslationsReady } = useLoadI18nextResource(TranslationScopes.BetSlip);

    useEffect(() => {
        const isBetSlip = view === "bet_slip";
        if (!isBetSlip || (!isBetSlip && !isUserLoggedIn)) {
            setView("bet_slip");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betItems.length, isUserLoggedIn]);

    useEffect(() => {
        const cleanup = () => {
            clearAllItems();
        };

        return cleanup;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = useMemo(() => {
        switch (view) {
            case "bet_slip":
                return <BetSlipBets />;
            case "history":
                return <BetSlipHistoryBlock />;
            default:
                return null;
        }
    }, [view]);

    return (
        <ErrorBoundary name={BetSlip.name}>
            <section id="bet-slip-container" className={styles.betView}>
                {isTranslationsReady ? (
                    <>
                        <BetSlipHeader setView={setView} view={view} isLoggedIn={isUserLoggedIn} />

                        {renderContent}
                        {items.map((item) => (
                            <Draggable2
                                key={item.id}
                                id={item.id.toString()}
                                renderTitle={
                                    <>
                                        <Svg
                                            src={`/common/sport-icons/${item.payload?.sport_id}.svg`}
                                            wrapper="span"
                                            className="svg-sport-icon"
                                            loader
                                        />
                                        {item.payload?.home_team} vs {item.payload?.away_team}
                                    </>
                                }
                                position={{
                                    top: item.top,
                                    left: item.left,
                                }}
                                width={280}>
                                <SIRWidgetModal
                                    key={item.payload?.event_id}
                                    matchId={item.payload?.event_id}
                                    regionId={item.payload?.category_id}
                                    sportId={item.payload?.sport_id}
                                    containerSelector={`#body #id_${item.payload?.event_id}`}>
                                    {(isLicenced, loading) => (
                                        <SIRWidgetCore isLicenced={isLicenced} loading={loading} />
                                    )}
                                </SIRWidgetModal>
                            </Draggable2>
                        ))}
                    </>
                ) : (
                    <Loading />
                )}
            </section>
        </ErrorBoundary>
    );
};

export default BetSlip;
