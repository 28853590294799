import { FC, useCallback, useMemo, FormEvent, useContext } from "react";
import { IMaxTicketAmountResponse } from "@finbackoffice/clientbff-client";
import { TranslationScopes, isCryptoCurrency } from "@finbackoffice/enums";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import InputField from "components/base/input-field/InputField";
import Translate from "components/base/translate/Translate";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import styles from "./stake-item.module.sass";

interface IStakeItemProps {
    value: string;
    onStakeChange: (value: string) => void;
    onBetMax?: () => Promise<void>;
    isBetMaxDisabled?: boolean;
    disabled?: boolean;
    limits: IMaxTicketAmountResponse | null;
}

const StakeItem: FC<IStakeItemProps> = ({
    value,
    onStakeChange,
    isBetMaxDisabled,
    onBetMax,
    disabled,
    limits,
}) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteConfigReady } = useContext(ConfigContext);
    const { betItems } = useContext(BetSlipContext);
    const { currentCurrency, userCurrency, currentWalletId } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { userCurrencyExchangeRate, fxCurrencyExchangeRate } = useContext(ExchangeRatesContext);
    const minBet = limits && parseFloat(limits.min_bet);
    const maxBet = limits && parseFloat(limits.amount);
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;
    const exchangeRate = fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate;
    // const isBetProcessorEnabled = useShallowSelector(selectIsBetProcessorEnabled);

    const renderMinMaxBet = useMemo(() => {
        // if (isBetProcessorEnabled) {
        if (isUserLoggedIn && !currentWalletId) {
            return <p />;
        }

        return (
            <p>
                {siteConfigReady && currentCurrency ? (
                    <>
                        <Translate tid="betSlip_min" namespace={TranslationScopes.BetSlip} />{" "}
                        {minBet ? (
                            <CurrencyFormatter
                                currency={currentCurrency}
                                amount={minBet.toString()}
                                withCode={false}
                            />
                        ) : (
                            "N/A"
                        )}
                        , <Translate tid="betSlip_max" namespace={TranslationScopes.BetSlip} />{" "}
                        {maxBet ? (
                            <CurrencyFormatter
                                currency={currentCurrency}
                                amount={maxBet.toString()}
                                withCode={false}
                            />
                        ) : (
                            "N/A"
                        )}
                    </>
                ) : (
                    "..."
                )}
            </p>
        );
        // }
        // return null;
    }, [isUserLoggedIn, currentWalletId, siteConfigReady, currentCurrency, minBet, maxBet]);

    const handleInputChange = useCallback(
        (event: FormEvent<Element>) => {
            const numbersOrDotRegexp = /^[0-9]+\.?[0-9]*$/;
            let newValue = (event.target as HTMLInputElement).value;
            const dotIndex: number = newValue.indexOf(".");

            if (!Boolean(newValue.length) || numbersOrDotRegexp.test(newValue)) {
                if (newValue.length && dotIndex === -1) {
                    newValue = parseFloat(newValue).toString();
                }

                onStakeChange(newValue);
            }
        },
        [onStakeChange],
    );

    return (
        <div className={styles.stakeItemContainer}>
            {Boolean(betItems.length) && renderMinMaxBet}
            <InputField
                showFocus
                className={styles.stakeItem}
                placeholder="0"
                type="text"
                data-testid="stake-item"
                value={value}
                onChange={handleInputChange}
                name="stake"
                disabled={!siteConfigReady || disabled}
            />
            {currentCurrency && (
                <Svg
                    src={`/common/currencies/${currentCurrency.toLowerCase()}.svg`}
                    wrapper="span"
                    className="svg-icon"
                />
            )}

            <Button
                type="button"
                onClick={onBetMax}
                className={styles.betMaxBtn}
                disabled={!siteConfigReady || !isUserLoggedIn || isBetMaxDisabled}>
                <Translate tid="betSlip_betMax" namespace={TranslationScopes.BetSlip} />
            </Button>
            {isUserLoggedIn &&
                exchangeRate &&
                currentCurrency &&
                isCryptoCurrency(currentCurrency) && (
                    <CurrencyFormatter
                        currency={fiatEnabled ? userCurrency : fxCurrency}
                        amount={((value ? parseFloat(value) : 0) * exchangeRate).toString()}
                        withCode={false}
                        withSymbol
                    />
                )}
        </div>
    );
};

export default StakeItem;
