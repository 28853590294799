import { useMemo, FC, useContext } from "react";
import { TranslationScopes, isCryptoCurrency } from "@finbackoffice/enums";
import {
    AuthContext,
    BetSlipContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import styles from "../total-stake.module.sass";

const SingleTotalStake: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { currentCurrency, userCurrency } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { userCurrencyExchangeRate, fxCurrencyExchangeRate } = useContext(ExchangeRatesContext);
    const { totalStake, betItems } = useContext(BetSlipContext);
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;
    const exchangeRate = fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate;

    const possibleWin: number = useMemo(() => {
        let win = 0;

        for (let i = 0; i < betItems.length; ++i) {
            win +=
                parseFloat(betItems[i].outcomeValue) *
                (betItems[i].stake ? parseFloat(betItems[i].stake) : 0);
        }

        return win;
    }, [betItems]);

    return (
        <div className={styles.totalStake}>
            <div className={styles.totalStakeItem}>
                <span>
                    <Translate tid="betSlip_totalStake" namespace={TranslationScopes.BetSlip} />
                </span>
                <span>
                    {currentCurrency && (
                        <strong>
                            <CurrencyFormatter
                                currency={currentCurrency}
                                amount={totalStake.toString()}
                                withCode={false}
                            />{" "}
                            <Svg
                                src={`/common/currencies/${currentCurrency.toLowerCase()}.svg`}
                                wrapper="span"
                                className="svg-icon"
                            />
                        </strong>
                    )}
                    {isUserLoggedIn &&
                        exchangeRate &&
                        currentCurrency &&
                        isCryptoCurrency(currentCurrency) && (
                            <CurrencyFormatter
                                currency={fiatEnabled ? userCurrency : fxCurrency}
                                amount={(totalStake * exchangeRate).toString()}
                                withCode={false}
                                withSymbol
                            />
                        )}
                </span>
            </div>
            <div className={styles.totalStakeItem}>
                <span>
                    <Translate tid="betSlip_possibleWin" namespace={TranslationScopes.BetSlip} />
                </span>
                <span>
                    {currentCurrency && (
                        <strong>
                            <CurrencyFormatter
                                currency={currentCurrency}
                                amount={possibleWin.toString()}
                                withCode={false}
                            />{" "}
                            <Svg
                                src={`/common/currencies/${currentCurrency?.toLowerCase()}.svg`}
                                wrapper="span"
                                className="svg-icon"
                            />
                        </strong>
                    )}
                    {isUserLoggedIn &&
                        exchangeRate &&
                        currentCurrency &&
                        isCryptoCurrency(currentCurrency) && (
                            <CurrencyFormatter
                                currency={fiatEnabled ? userCurrency : fxCurrency}
                                amount={(possibleWin * exchangeRate).toString()}
                                withCode={false}
                                withSymbol
                            />
                        )}
                </span>
            </div>
        </div>
    );
};

export default SingleTotalStake;
